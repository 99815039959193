<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <h3 class="title">公共配置后台管理系统</h3>
      <!-- 配置form表单验证
        1. 必须给 el-from 组件绑定 model 为表单数据对象
        2. 给需要验证的表单项 el-from-item 绑定prop 属性
        3. 配置 el-from 组件配置 rules 验证规则
      -->
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          type="text"
          auto-complete="off"
          placeholder="账号">
          <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="密码"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>

      <!--复选框-->
      <el-checkbox v-model="loginForm.rememberMe" style="margin: 0px 0px 25px 0px">记住密码</el-checkbox>
      <el-form-item style="width: 100%">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width: 100%;"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登录</span>
          <span v-else>登录中……</span>
        </el-button>
      </el-form-item>
      </el-form>
      <div class="el-login-footer">
        <span>Copyright © 2021-2050 Wondershare.Shijh All Rights Reserved.</span>
      </div>
  </div>
</template>

<script>
  import Cookies from "js-cookie";
  import { encrypt, decrypt } from '@/utils/jsencrypt'
  export default {
    name: "Login",
    data() {
      return {
        codeUrl: "",
        loginForm: {
          username: 'admin',  // 账号
          password: '123456', // 密码
          rememberMe: false,  // 是否记住密码
          code: '',           // 验证码
          uuid: ''
        },
        loginRules: { // 登录表单验证规则
          username: [{required: true, trigger: 'blur', message: "用户名不能为空"}],
          password: [{required: true, trigger: 'blur', message: "密码不能为空"}],
          code: [{ required: true, trigger: "change", message: "验证码不能为空" }]
        },
        loading: false
      }
    },
    created() {
      this.getCode();
      this.getCookie();
    },
    methods: {
      getCode(){
        // getCodeImg().then(res => {
        //   this.codeUrl = "data:image/gif;base64," + res.img;
        //   this.loginForm.uuid = res.uuid;
        // })
      },
      getCookie() {
        const username = Cookies.get("username");
        const password = Cookies.get("password");
        const rememberMe = Cookies.get('rememberMe')
        this.loginForm = {
          username: username === undefined ? this.loginForm.username : username,
          password: password === undefined ? this.loginForm.password : decrypt(password),
          rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
        };
      },
      // 登录
      handleLogin() {
        // 获取表单数据

        // 处理后端响应效果
        // 表单验证
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.loading = true
            if (this.loginForm.rememberMe) {
              Cookies.set("username", this.loginForm.username, { expires: 30 });
              Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
              Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
            } else {
              Cookies.remove("username");
              Cookies.remove("password");
              Cookies.remove('rememberMe');
            }
            // 验证通过，提交登录
            this.$store.dispatch("Login", this.loginForm).then(() => {
              // 路由跳转至首页
              this.$router.push({ path: this.redirect || "/" }).catch(res=>{
              });
            }).catch(() => {
              this.loading = false;
            });
          } else {  // 验证失败
            console.log('error submit');
            return false
          }
        })
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">
  $bg:#2d3a4b;
  $dark_gray:#889aa4;
  $light_gray:#eee;

  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url("../../assets/images/login-background.jpg");
    background-size: cover;
  }
  .title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .login-form {
    border-radius: 6px;
    background: #fff;
    width: 400px;
    padding: 25px 25px 5px 25px;
    .el-input {
      height: 38px;
      input {
        height: 38px;
      }
    }
    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }
  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }
  .login-code {
    width: 33%;
    height: 38px;
    float: right;
    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .login-code-img {
    height: 38px;
  }
</style>
